<template>
    <div class="overview-filters-page" :class="{'inside-subheader': subFilter}">
        <Button
            :border="border"
            blue
            :green="displayMenu"
            small
            :click-event="toggleFiltersPopup"
            :disabled="filtersDisabled"
            :key="displayMenu"
        >
            <img alt="..." src="@/assets/icons/filter.svg"/>
        </Button>

        <div class="overview-filters-container" :class="!displayMenu && 'hidden'">
            <div class="filters-container" v-if="!subFilter">
                <Searchbar
                    :disableSelect="'true'"
                    placeholder="on_site.search_filter.construction_site.placeholder"
                />
                <Button
                    green
                    small
                    :click-event="toggleFiltersPopup"
                    :disabled="filtersDisabled"
                >
                    <img alt="..." src="@/assets/icons/filter.svg"/>
                </Button>
            </div>

            <Button
                bold
                disabled
                full-width
                :key="allFilters"
                red
                :click-event="resetFilters"
                v-show="showFilters || showDatepicker"
            >
                {{ "on_site.filters.delete_all_filters".trans() }}
            </Button>
            <Loader>
                <div class="filters-main-content" v-show="showFilters" :key="showFilters">
                    <div class="filter-tags-container">
                        <div class="filter-tags" v-for='(value, name) in items' :key="`item-${name}`">
                            <p class="filter-name">{{ `on_site.filter_category.${name}`.trans() }}</p>
                            <div class="tags-container">
                                <div class="filter-tag" :class="name, activeFilters.includes(index) && 'active'"
                                     v-for='(filterTag, index) in value'
                                     :key="`item-${index}`"
                                     @click="sortFilters($event)"
                                     :data-filter-id="index"
                                     :data-filter-type="name"
                                >{{ filterTag }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="filters-main-content-empty" v-show="!showFilters">
                    {{'on_site.filter_page.empty'.trans()}}
                </div>
                <div class="filters-main-content-empty" v-show="!showFilters && selectedFilters !== '' && selectedQuery !== ''">
                    {{'on_site.filter_page.empty_because_of_search_term'.trans()}}
                </div>
            </Loader>
            <Button
                bold
                disabled
                full-width
                green
                :key="allFilters"
                :click-event="setFilters"
                v-show="showFilters || showDatepicker || isFilterSet"
            >
                {{ "on_site.filters.apply_filters".trans() }}
            </Button>
            <div class="filter-datepicker-container" v-if="showDatepicker">
                <p class="filter-name">{{ 'on_site.filter_category.datepicker'.trans() }}</p>
                <flat-pickr v-model="dateFilter"
                            placeholder="Select date"
                            :config="config"
                            @onChange="setFilterDate"
                ></flat-pickr>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, onUpdated, ref } from 'vue'
import { useStore } from 'vuex'
import Searchbar from '@/components/Common/Searchbar.vue'
import Loader from '@/components/Common/Loader.vue'
import { updateUrlParameterMulti } from '@/utils/updateFilterUrl.js'
import { updateUrlParameter } from '@/utils/updateFilterUrlSingle.js'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { German } from 'flatpickr/dist/l10n/de'
import Button from "@/components/Common/Button.vue";

const loading = computed(() => store.getters['getLoadingCalls'])

const {
    border,
    filterAction,
    filterParameter,
    filterRoute,
    subFilter,
    contractItems,
    parameterName,
    parameterValue,
    pageName,
    showDatepicker
} = defineProps({
    border: Boolean,
    filterAction: String,
    filterParameter: Function,
    filterRoute: String,
    subFilter: {
        type: Boolean,
        default: false
    },
    contractItems: {
        type: Boolean,
        default: false
    },
    parameterName: String,
    parameterValue: String,
    pageName: String,
    showDatepicker: {
        type: Boolean,
        default: false
    },
    filtersDisabled: {
        type: Boolean,
        default: false
    }
})

const store = useStore()
const activeFilters = ref([])
const displayMenu = ref(false)
let isFilterSet = ref(false)

onMounted(() => {
    if(parameterName && parameterValue) {
        store.dispatch(filterRoute, {
            [parameterName]: parameterValue,
        })
    } else {
        store.dispatch(filterRoute)
    }
})

// Variable for creating the all filters string
let allFilters = ''

// Datepicker configuration
const dateFilter = ref('')

const config = {
    inline: true,
    dateFormat: "d-m-Y",
    locale: German,
    monthSelectorType: "static",
    onDayCreate: function(dObj, dStr, fp, dayElem){
        // Logic for adding a class to weekend days
        const dayOfWeek = dayElem.dateObj.getDay()
        const isWeekend = (dayOfWeek === 6) || (dayOfWeek  === 0)

        if(isWeekend) {
            dayElem.classList.add('weekendDay')
        }
    }
}

// Logic for updating all filters with date
let selectedDate = ''
function setFilterDate() {
    const dateFilterValue = dateFilter.value

    selectedDate = dateFilterValue

    allFilters = updateUrlParameter(allFilters, 'date', dateFilterValue)

    let payload = {
        [parameterName]: parameterValue,
        'filterString': allFilters
    }

    if (selectedQuery.value) {
        payload = {
            ...payload,
            query: selectedQuery.value
        }
    }

    store.dispatch(filterRoute, payload)
}

let items = ''
if(pageName === 'daily') {
    items = computed(() => store.state.filters.dailyAmountFilterItems)
} else if(pageName === 'total') {
    items = computed(() => store.state.filters.totalAmountFilterItems)
} else if(pageName === 'confirmed') {
    items = computed(() => store.state.filters.confirmedDeliveryFilterItems)
} else if(pageName === 'incoming') {
    items = computed(() => store.state.filters.incomingDeliveryFilterItems)
} else if(pageName === 'contractItems') {
    items = computed(() => store.state.filters.contractItemsFilterItems)
} else if(pageName === 'calloffList') {
    items = computed(() => store.state.filters.contractItemsFilterItems)
} else {
    items = computed(() => store.state.filters.contractFilterItems)
}

const showFilters = computed(() => {
    const totalItemCount = Object.values(items.value).reduce((x, y) => Object.values(x).length + Object.values(y).length, 0)
    const isShowFilters = totalItemCount !== 0

    toggleFilterButtons()

    return isShowFilters
})

const selectedQuery = computed(() => store.state.filters.selectedQuery)
const selectedFilters = computed(() => store.state.filters.selectedFilters)

// Logic for opening/closing filters popup and hidding contracts when filters popup is open
function toggleFiltersPopup() {
    displayMenu.value = !displayMenu.value

    // Toggle scroll and/or pull to refresh logic on body
    /*if (filterIsOpen) {
            if (props.subFilter) {
                // this class only disables pull-to-refresh but keeps the scroll behavior
                $('html').addClass('no-scroll')

            } else {
                // this class disables scrolling which also disables pull-to-refresh
                $('html').addClass('no-pull-to-refresh')
            }
        } else {*/
    $('html').removeClass(subFilter ? 'no-scroll' : 'no-pull-to-refresh')
    /*}*/

    toggleFilterButtons()
}

// Logic for sorting filters when one is clicked
const sortFilters = (event) => {
    event.target.classList.toggle('active')
    const filterKey = event.target.dataset.filterType
    const filterValue = event.target.dataset.filterId
    activeFilters.value = activeFilters.value.includes(filterValue) ? activeFilters.value.filter(filter => filter !== filterValue) : [...activeFilters.value, filterValue]

    allFilters = updateUrlParameterMulti(allFilters, filterKey, filterValue)

    isFilterSet = allFilters !== '';

    let payload = {
        [parameterName]: parameterValue,
        'filterString': allFilters
    }

    if (selectedQuery.value) {
        payload = {
            ...payload,
            query: selectedQuery.value
        }
    }

    store.dispatch(filterRoute, payload)
}

function setFilters(event) {
    let payload = {
        [parameterName]: parameterValue,
        'filterString': allFilters
    }

    if (selectedQuery.value) {
        payload = {
            ...payload,
            query: selectedQuery.value
        }
    }

    store.dispatch(filterAction, payload)

    filterParameter(allFilters)

    // Close filter popup
    $('.overview-filters-container').addClass('hidden')
    $('.contracts-container').removeClass('hidden')

    store.commit('filters/setSelectedFilters', allFilters)
    store.commit('filters/setSelectedDate', selectedDate)

    $('.filter-parent-container').removeClass('hidden')

    $('html').removeClass('no-scroll')
    $('html').removeClass('no-pull-to-refresh')
    displayMenu.value = false
}

function resetFilters() {
    store.dispatch(filterRoute, {
        [parameterName]: parameterValue,
    })

    let payload = {
        [parameterName]: parameterValue,
        'filterString': ''
    }

    if (selectedQuery.value) {
        payload = {
            ...payload,
            query: selectedQuery.value
        }
    }

    store.dispatch(filterAction, payload)

    // Reset filters popup - disable buttons, deactivate filter tags
    $('.filter-tag').removeClass('active')

    // Close filter popup and show item container
    $('.overview-filters-container').addClass('hidden')
    $('.contracts-container').removeClass('hidden')

    allFilters = ''
    filterParameter(allFilters)

    dateFilter.value = ''

    store.commit('filters/setSelectedFilters', '')
    store.commit('filters/setSelectedDate', '')

    $('.filter-parent-container').removeClass('hidden')

    $('html').removeClass('no-scroll')
    $('html').removeClass('no-pull-to-refresh')
    activeFilters.value = []
    displayMenu.value = false
}

function toggleFilterButtons() {
    setTimeout(function() {
        if(allFilters === '') {
            $('.overview-filters-container > button').addClass('disabled')
        } else {
            $('.overview-filters-container > button').removeClass('disabled')
        }
    }, 100)
}

</script>

<style lang="sass" scoped>
.overview-filters-page
    .overview-filters-container
        background-color: $white //$background-gray
        box-shadow: 0 3px 6px #01000029
        display: flex
        flex-direction: column
        gap: 10px
        height: calc(100vh - 151px)
        left: 0
        overflow: auto
        padding: 11px 24px 60px
        position: absolute
        top: -10px
        transition: 1s
        width: 100%
        z-index: 2

        &.hidden
            display: none

        .filters-main-content-empty
            margin-top: 30px
            margin-left: 24px
            text-align: left

        .filters-title
            margin: 0px
            padding-top: 12px
            padding-left: 24px
            font-family: PoppinsMedium
            font-size: 12px
            letter-spacing: 0.02px
            line-height: 16px
            text-align: left
            color: $text-gray

        .filter-tags-container
            text-align: left

            .filter-tags
                display: flex
                flex-direction: column
                margin-bottom: 15px

                .filter-name
                    margin-bottom: 5px
                    font-family: PoppinsBold
                    font-size: 12px
                    letter-spacing: 0.02px
                    line-height: 16px
                    color: $dark-blue

                .tags-container
                    display: flex
                    flex-wrap: wrap

                    .filter-tag
                        margin-right: 5px
                        margin-bottom: 5px
                        border: 1px solid $gray-blue
                        border-radius: 4px
                        height: 40px
                        padding: 0 20px
                        background-color: $white

                        font-family: PoppinsRegular
                        font-size: 14px
                        letter-spacing: 0.03px
                        line-height: 40px
                        color: $text-lighter-gray
                        cursor: pointer

                        &:hover
                            background-color: $button-blue
                            color: $white

                        &.active
                            background-color: $button-blue
                            color: $white

    &.inside-subheader
        .overview-filters-container
            top: 46px

// fix for safari web mode
.app-container
    &:not(.standalone)
        &.ios
            .apply-filters-button
                margin-bottom: 20px

        &.ios-old
            .apply-filters-button
                margin-bottom: 60px

.dark-mode
    .overview-filters-page
        .overview-filters-container
            background-color: $dark-button-gray

            .filters-main-content-empty
                color: $dark-text-color

            .filter-tags-container
                .filter-tags
                    .filter-name
                        color: $dark-text-color

                    .filter-tag
                        background-color: $text-black
                        color: $dark-text-color

    .select2-container--default.select2-container--disabled .select2-selection--single
        background-color: $text-black !important
        color: $dark-text-color !important
</style>

<style lang="sass">
.dark-mode
    .overview-filters-page
        .select2-container--default.select2-container--disabled .select2-selection--single
            background-color: $text-black !important
            color: $dark-text-color !important

    .filter-datepicker-container
        .filter-name
            color: $dark-text-color

        .flatpickr-calendar
            background-color: $dark-button-gray

            .flatpickr-months
                .flatpickr-prev-month,
                .flatpickr-next-month
                    svg
                        fill: $dark-text-color

                .flatpickr-current-month
                    color: $dark-text-color

            .flatpickr-weekdays
                .flatpickr-weekday
                    color: $white

            .flatpickr-days
                .dayContainer
                    .flatpickr-day
                        color: $dark-text-color

                        &.selected
                            background-color: $button-blue

                        &.weekendDay
                            background-color: $flatpicker-dark

                            &.selected
                                background-color: $button-blue

                        &:hover
                            border: $button-blue
                            background-color: $button-blue

            .flatpickr-weekdaycontainer span:nth-child(7n+6),
            .flatpickr-weekdaycontainer span:nth-child(7n)
                background-color: $flatpicker-dark

.filter-datepicker-container
    margin: 0 auto
    width: 94%

    .flatpickr-input
        display: none

    .filter-name
        margin-bottom: 5px
        font-family: PoppinsBold
        font-size: 12px
        letter-spacing: 0.02px
        line-height: 16px
        color: $dark-blue
        text-align: left

    .flatpickr-calendar
        width: 100%
        max-width: 355px
        margin: 0 auto
        box-shadow: none

    .flatpickr-months
        .flatpickr-current-month
            font-family: PoppinsMedium
            font-size: 18px
            line-height: 27px
            color: $dark-blue

        .flatpickr-next-month
            right: 5px

    .flatpickr-rContainer
        margin: 0 auto

    .flatpickr-weekdays
        font-family: PoppinsLight
        font-size: 16px
        height: 50px
        color: $text-lighter-gray

        .flatpickr-weekdaycontainer
            height: 50px

            .flatpickr-weekday
                line-height: 50px

    .flatpickr-days
        min-width: 350px

        .dayContainer
            min-width: 350px

            .flatpickr-day
                min-width: 50px
                height: 50px
                font-family: PoppinsRegular
                font-size: 13px
                line-height: 49px
                color: $dark-blue

                &.selected
                    border: none
                    color: $white
                    background-color: $dark-blue

                &.weekendDay
                    border-radius: 0px
                    background-color: $datepicker-gray

                    &.selected
                        background-color: $dark-blue

            .prevMonthDay
                color: $text-lighter-gray

    .flatpickr-weekdaycontainer span:nth-child(7n+6),
    .flatpickr-weekdaycontainer span:nth-child(7n)
        background-color: $datepicker-gray

@media (max-width: 360px)
    .filter-datepicker-container
        .flatpickr-days
            min-width: 300px

            .dayContainer
                min-width: 300px

                .flatpickr-day
                    min-width: 43.82px
                    height: 43.82px
                    line-height: 47px
</style>