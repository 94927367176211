import { makeRequest } from '@/utils/makeRequest'
import moment from 'moment'

const state = {
    recentDeliveryItems: {},
    incomingDeliveryItems: {
        items: [],
        pagination: {}
    },
    confirmedDeliveryItems: {
        items: [],
        pagination: {}
    },
    deliverySingle: {},
    deliverySingleComingFrom: '',
    isDeliveryItemsRequesting: false,
    isDeliveryItemsPaginationRequesting: false
}

const getters = {

}

const actions = {
    /**
     * Gets max 5 last daily amounts that user visited
     * 
     * @param {object} params - Optional extra parameters. By default it includes the selected company's number stored in user module.
     */
    fetchRecentDeliveries: async (
        { commit, rootState }, 
        params = { 'company_number': rootState.user.selectedCompany.company_nr }
    ) => {
        commit('setDeliveryItemsRequestingFlag', true)
        try {
            const response = await makeRequest('get', 'api_delivery_notes_incoming_latest', params)
            const { data } = response

            commit('setRecentDeliveryItems', data)
            commit('setDeliveryItemsRequestingFlag', false)

        } catch(err) {
            commit('setDeliveryItemsRequestingFlag', false)
            throw err
        }
    },
    fetchIncomingDeliveries: async (
        { commit, rootState },
        params = { 'contract_number': rootState.contracts.selectedContract.contract_number }
    ) => {

        if (params.page) {
            commit('setDeliveryItemsRequestingPaginatedFlag', true)

        } else {
            commit('setDeliveryItemsRequestingFlag', true)
        }

        try {
            const response = await makeRequest('get', 'api_deliveries_incoming', params)
            const { data } = response

            // if paginated results were required, append them to the current ones
            if (params.page) {
                commit('appendPaginatedIncomingDeliveryItems', data)
                commit('setDeliveryItemsRequestingPaginatedFlag', false)

            } else {
                commit('setIncomingDeliveryItems', data)
                commit('setDeliveryItemsRequestingFlag', false)
            }

        } catch(err) {
            commit('setDeliveryItemsRequestingFlag', false)
            commit('setDeliveryItemsRequestingPaginatedFlag', false)
            throw err
        }
    },
    fetchConfirmedDeliveries: async (
        { commit, rootState },
        params = { 'contract_number': rootState.contracts.selectedContract.contract_number }
    ) => {

        if (params.page) {
            commit('setDeliveryItemsRequestingPaginatedFlag', true)

        } else {
            commit('setDeliveryItemsRequestingFlag', true)
        }

        try {
            const response = await makeRequest('get', 'api_deliveries_confirmed', params)
            const { data } = response

            // if paginated results were required, append them to the current ones
            if (params.page) {
                commit('appendPaginatedConfirmedDeliveryItems', data)
                commit('setDeliveryItemsRequestingPaginatedFlag', false)

            } else {
                commit('setConfirmedDeliveryItems', data)
                commit('setDeliveryItemsRequestingFlag', false)
            }

        } catch(err) {
            commit('setDeliveryItemsRequestingFlag', false)
            commit('setDeliveryItemsRequestingPaginatedFlag', false)
            throw err
        }
    },
    fetchDeliverySingle: async ({ commit, state }, id) => {
        commit('setDeliveryItemsRequestingFlag', true)

        try {
            const response = await makeRequest('get', 'api_delivery_details', { 'delivery_id': id })
            const { data } = response

            commit('setDeliverySingleData', data)
            commit('setDeliveryItemsRequestingFlag', false)

        } catch(err) {
            commit('setDeliveryItemsRequestingFlag', false)
            throw err
        }
    },
    /**
     * Sends request for confirming the delivery. Also manually change the current delivery status and confirmed time
     * so that we dont need to send another request for latest data.
     */
    confirmDelivery: async ({ commit, state }, id) => {
        commit('setDeliveryItemsRequestingFlag', true)

        try {
            await makeRequest('post', 'api_confirm_delivery_note', { 'delivery_id': id })

            const date = moment().format('DD.MM.YYYY')
            const time = moment().format('HH:mm')

            commit('setDeliverySingleStatus', '1')
            commit('setDeliverySingleConfirmedTime', { date, time })

            commit('setDeliveryItemsRequestingFlag', false)

        } catch(err) {
            commit('setDeliveryItemsRequestingFlag', false)
            throw err
        }
    }
}

const mutations = {
    setRecentDeliveryItems: (state, value) => {
        state.recentDeliveryItems = value
    },
    setIncomingDeliveryItems: (state, value) => {
        state.incomingDeliveryItems = value
    },
    appendPaginatedIncomingDeliveryItems: (state, deliveryItems) => {
        const { items, pagination } = deliveryItems
        state.incomingDeliveryItems = {
            items: [ ...state.incomingDeliveryItems.items, ...items ],
            pagination
        }
    },
    setConfirmedDeliveryItems: (state, value) => {
        state.confirmedDeliveryItems = value
    },
    appendPaginatedConfirmedDeliveryItems: (state, deliveryItems) => {
        const { items, pagination } = deliveryItems
        state.confirmedDeliveryItems = {
            items: [ ...state.confirmedDeliveryItems.items, ...items ],
            pagination
        }
    },
    setDeliverySingleData: (state, data) => {
        state.deliverySingle = data
    },
    setDeliverySingleStatus: (state, status) => {
        state.deliverySingle.status = status
    },
    setDeliverySingleConfirmedTime: (state, { date, time }) => {
        state.deliverySingle.confirmed_date = date
        state.deliverySingle.confirmed_time = time
    },
    setDeliverySingleComingFrom: (state, value) => {
        state.deliverySingleComingFrom = value
    },
    setDeliveryItemsRequestingFlag: (state, value) => {
        state.isDeliveryItemsRequesting = value
    },
    setDeliveryItemsRequestingPaginatedFlag: (state, value) => {
        state.isDeliveryItemsPaginationRequesting = value
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
