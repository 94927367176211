<template>
    <div class="total-amounts-page">
        <Header :pageTitle="pageTitle"/>
        <SubHeader
            :current-page="currentPage"
            filter-action="amountItems/fetchTotalAmountsOnLoad"
            filter-route="filters/fetchTotalAmountFilterItems"
            :sub-filter="true"
            parameter-name="contract_number"
            :parameter-value="store.state.contracts.selectedContract.contract_number"
            @filter-parameter="getFiltersParameter"
            page-name="total"
            :filters-disabled="!(totalAmountItems && totalAmountItems.length > 0) && selectedFilters === ''"
        />

        <Loader v-if="isRequestingTotalAmounts" />
        <div v-else>
            <div class="total-amounts-container filter-parent-container" v-if="totalAmountItems && totalAmountItems.length">
                <AmountCard
                    v-for="item in totalAmountItems"
                    :key="`item-${item.id}`"

                    cardType="total"
                    :materialName="item.material_designation"
                    :materialNumber="item.material_number"
                    :targetAmountRaw="item.target_quantity_raw"
                    :targetAmount="item.target_quantity"
                    :deliveredAmountRaw="item.delivered_quantity_raw"
                    :deliveredAmount="item.delivered_quantity"
                    :incomingAmountRaw="item.incoming_quantity_raw"
                    :incomingAmount="item.incoming_quantity"
                    :overDeliveredAmount="item.over_delivered_quantity"
                    :overDeliveredAmountRaw="item.over_delivered_quantity_raw"
                    :openAmountRaw="item.remaining_quantity_raw"
                    :openAmount="item.remaining_quantity"
                    :factoryName="item.factory_name"
                    :factoryNumber="item.factory_number"
                    :quantityUnit="item.quantity_unit"
                />
            </div>
            <div class="total-amounts-title empty" v-else>
                <span class="empty-title">{{'on_site.total_amounts.empty'.trans()}}</span>
                <div class="empty-screen-icon-container">
                    <img class="amount" src="@/assets/icons/amount-empty.svg" alt="empty_amount"/>
                </div>
               <span class="empty-message">{{'on_site.total_amounts.empty_message'.trans()}}</span>
            </div>
        </div>

        <Footer/>
    </div>
</template>

<script setup>
    import { computed, onMounted, onUnmounted  } from 'vue'
    import { useStore } from 'vuex'
    import Header from '@/components/Common/Header.vue'
    import SubHeader from '@/components/SubHeader/SubHeader.vue'
	import Loader from '@/components/Common/Loader.vue'
    import AmountCard from '@/components/ContractDetails/AmountCard.vue'
    import Footer from '@/components/Common/Footer.vue'

    const currentPage = 'on_site.menu.total_quantity'.trans()

    const store = useStore()

    const pageTitle = computed(() => store.state.contracts.selectedContract.contract_name)

    // Recent items loading flag
    const isRequestingTotalAmounts = computed(() => store.state.amountItems.isAmountsRequesting)
    const isRequestingPaginated = computed(() => store.state.amountItems.isAmountsPaginationRequesting)

    // The request for data was fired in the route guard
	const totalAmountItems = computed(() => store.state.amountItems.totalAmountItems.items)
    const pagination = computed(() => store.state.amountItems.totalAmountItems.pagination)

    // Logic for enabling filters when there are no items
    const selectedFilters = computed(() => store.state.filters.selectedFilters)

    // Filters parameter from filters popup - used for pagination
	let filtersParameter = ''
	function getFiltersParameter(val) {
		const openFiltersButton = $('.open-filters-popup')
		filtersParameter = val
	}

    // Pagination logic
	const initializeInfiniteScroll = () => {
		window.onscroll = () => {
			const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.documentElement.offsetHeight
			if (bottomOfWindow && !isRequestingTotalAmounts.value && !isRequestingPaginated.value) {
				const { current_page, page_size, total_items, total_pages, has_next_page } = pagination.value
				if (has_next_page && !$('.filter-parent-container').hasClass('hidden')) {
                    store.dispatch('amountItems/fetchTotalAmountItemsPaginated', {
                        contract_number: store.state.contracts.selectedContract.contract_number,
                        page: current_page + 1,
                        page_size: page_size,
                        'filterString': filtersParameter
                    })
				}
			}
		}
	}

    onUnmounted(() => {
        store.commit('filters/setSelectedFilters', '')
    })

	onMounted(() => {
		initializeInfiniteScroll()
	})
</script>

<style lang="sass" scoped>
.total-amounts-page
	padding-top: 51px + 48px // header + subheader
	padding-bottom: 81px // footer + some extra height
	min-height: calc(100vh - 51px - 48px - 81px)

	.total-amounts-title
		margin: 0px
		padding-top: 12px
		padding-left: 24px
		font-family: PoppinsMedium
		font-size: 12px
		letter-spacing: 0.02px
		line-height: 16px
		text-align: left
		color: $text-gray

		&.empty
			margin-top: 15px
			padding-left: 0px
			text-align: center
			font-family: PoppinsMedium
			font-size: 12px
			color: $text-gray

			.empty-screen-icon-container
				margin-bottom: 10px

				img
					height: 200px

			.empty-title
				font-family: PoppinsBold
				font-size: 16px

	.total-amounts-container
		display: flex
		justify-content: space-evenly
		flex-wrap: wrap
		padding-top: 5px
			
		&.hidden
			display: none
</style>