<template>
    <div class="daily-amount-card" :class="{'clickable': isSliderItem}" @click="redirectToDailyView">
        <div class="daily-amount-header">
            <div class="daily-amount-material">
                <img alt=" " src="../../assets/icons/amount-dark-gray.svg"/>
                <span class="title">{{ "on_site.daily_amount.material".trans() }}</span>
                <span class="name">{{ materialName ? materialName : '—' }}</span>
            </div>

            <div class="daily-amount-table-container">
                <table class="daily-amount-table">
                    <tr>
                        <td>{{ "on_site.daily_amount.material_number".trans() }}</td>
                        <td>{{ materialNumber ? materialNumber : '—' }}</td>
                    </tr>
                    <tr>
                        <td>{{ "on_site.daily_amount.factory".trans() }}</td>
                        <td>{{ factoryName ? `${factoryNumber} - ${factoryName}` : '—' }}</td>
                    </tr>
                </table>
            </div>
        </div>

        <div class="daily-amount-amounts">
            <div class="daily-amount-amounts-table-container">
                <p class="daily-amount-table-title">
                    <span class="title" v-if="cardType == 'daily'">{{ "on_site.daily_amount.daily_amount".trans() }}</span>
                    <span class="title" v-else>{{ "on_site.daily_amount.total_amount".trans() }}</span>
                    <span class="data">{{ targetAmount ? `${targetAmount} ${quantityUnit}` : '—' }}</span>
                </p>

                <table class="daily-amount-table">
                    <template v-if="showDeliveredAndIncoming">
                        <tr>
                            <td class="icon-container">
                                <img alt=" " src="../../assets/icons/circle-green-full.svg"/>
                                {{ "on_site.daily_amount.delivered".trans() }}
                            </td>
                            <td>{{ deliveredAmount ? `${deliveredAmount} ${quantityUnit}` : '—' }}</td>
                        </tr>
                        <tr v-if="cardType == 'daily'">
                            <td class="icon-container">
                                <img alt=" " src="../../assets/icons/circle-blue-full.svg"/>
                                {{ "on_site.daily_amount.in_delivery".trans() }}
                            </td>
                            <td>{{ incomingAmount ? `${incomingAmount} ${quantityUnit}` : '—' }} </td>
                        </tr>
                    </template>
                    <tr v-if="overDeliveredAmountRaw > 0">
                        <td class="icon-container">
                            <img alt=" " src="../../assets/icons/circle-red-full.svg"/>
                            {{ "on_site.daily_amount.overdelivered".trans() }}
                        </td>
                        <td>{{ overDeliveredAmount ? `${overDeliveredAmount} ${quantityUnit}` : '—' }}</td>
                    </tr>
                    <tr v-if="openAmountRaw > 0">
                        <td class="icon-container">
                            <img alt=" " src="../../assets/icons/circle-gray-full.svg"/>
                            {{ "on_site.daily_amount.open".trans() }}
                        </td>
                        <td>{{ openAmount ? `${openAmount} ${quantityUnit}` : '—' }}</td>
                    </tr>
                </table>
            </div>
            <div class="donut-container" @click="donutClick">
                <Doughnut
                    :chart-options="{ cutout: '85%' }"
                    :styles="donutStyle"
                    :chart-data="donutData"
                />
                <div class="donut-text" :class="{'overdelivered': overDeliveredAmountRaw > 0, 'empty': donutTotalTonnage == 0}">
                    <span class="tonnage" v-show="showTonnage">
                        {{donutTotalTonnage.toLocaleString('de-DE')}}{{quantityUnit}}
                    </span>
                    <span class="percentage" v-show="!showTonnage">
                        {{Math.round(donutTotalPercentage).toLocaleString('de-DE')}}<span class="percentage-unit">%</span>
                    </span>
                </div>

            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed, ref, watch } from 'vue'
    import { Doughnut } from 'vue-chartjs'
    import { Chart, ArcElement } from 'chart.js'
    import { useStore } from 'vuex'
    import { useRouter } from 'vue-router'
    
    Chart.register(ArcElement)
    const store = useStore()
    const router = useRouter()

    const props = defineProps({
        cardType: {
            type: String,
            default: 'daily'
        },
        isSliderItem: {
            type: Boolean,
            default: false
        },
        materialName: String,
        materialNumber: String,
        targetAmount: String,
        targetAmountRaw: String,
        deliveredAmount: String,
        deliveredAmountRaw: String,
        incomingAmount: String,
        incomingAmountRaw: {
            type: String,
            default: '0'
        },
        overDeliveredAmount: String,
        overDeliveredAmountRaw: String,
        openAmount: String,
        openAmountRaw: String,
        factoryName: String,
        factoryNumber: String,
        quantityUnit: String
    })

    const contractId = computed(() => store.state.contracts.selectedContract.contract_id)

    const showTonnage = computed(() => store.state.amountItems.amountShowTonnage)

    // convert raw values to numbers
    const targetAmountRaw = parseFloat(props.targetAmountRaw)
    const deliveredAmountRaw = parseFloat(props.deliveredAmountRaw)
    const incomingAmountRaw = parseFloat(props.incomingAmountRaw)
    const overDeliveredAmountRaw = parseFloat(props.overDeliveredAmountRaw)
    const openAmountRaw = parseFloat(props.openAmountRaw)

    // dont show green and blue numbers if both of them are 0, in that case only gray is shown
    const showDeliveredAndIncoming = deliveredAmountRaw + incomingAmountRaw > 0

    // variables necessary for showing the percentages in circle
    let donutTotalTonnage = 0
    let donutTotalPercentage = 0
    let donutDeliveredPercentage = 0
    let donutIncomingPercentage = 0
    let donutOverDeliveredPercentage = 0

    // if there is open amount (gray value) take it into account
    if (openAmountRaw > 0) {
        donutTotalTonnage = deliveredAmountRaw + incomingAmountRaw
        donutTotalPercentage = (donutTotalTonnage / (donutTotalTonnage + openAmountRaw)) * 100

        donutDeliveredPercentage = (deliveredAmountRaw / (donutTotalTonnage + openAmountRaw)) * 100
        donutIncomingPercentage = (incomingAmountRaw / (donutTotalTonnage + openAmountRaw)) * 100

    } else {
        if (overDeliveredAmountRaw > 0) {
            donutTotalTonnage = deliveredAmountRaw + incomingAmountRaw
            donutTotalPercentage = (donutTotalTonnage / targetAmountRaw) * 100

            donutIncomingPercentage = (incomingAmountRaw / donutTotalTonnage) * 100
            donutOverDeliveredPercentage = (overDeliveredAmountRaw / donutTotalTonnage) * 100

        } else {
            donutTotalTonnage = deliveredAmountRaw + incomingAmountRaw

            if (donutTotalTonnage > 0) {
                donutTotalPercentage = targetAmountRaw > 0 ? (donutTotalTonnage / targetAmountRaw) * 100 : 100
            } else {
                donutTotalPercentage = 0
            }

            donutDeliveredPercentage = donutTotalTonnage > 0 ? (deliveredAmountRaw / donutTotalTonnage) * 100 : 0
            donutIncomingPercentage = donutTotalTonnage > 0 ? (incomingAmountRaw / donutTotalTonnage) * 100 : 0

            // if the sum of delivered and incoming is > 100 then scale it down to 100 in sum
            if (donutDeliveredPercentage + donutIncomingPercentage > 100) {
                const scaleTotal = donutDeliveredPercentage + donutIncomingPercentage
                
                donutDeliveredPercentage = (donutDeliveredPercentage / scaleTotal) * 100
                donutIncomingPercentage = (donutIncomingPercentage / scaleTotal) * 100
            }
        }
    }

    // if the number is 1000 or more dont show decimals
    if (donutTotalTonnage > 999) {
        donutTotalTonnage = Math.round(donutTotalTonnage)

    // otherwise always show 2 decimals, unless its 0
    } else if (donutTotalTonnage > 0) {
        // replace() is necessary because this is now a string and not a number so toLocaleString doesnt work
        donutTotalTonnage = donutTotalTonnage.toFixed(2).replace('.',',')
    }

    const donutData = ref({
        datasets: [
            {
                data: [],
                backgroundColor: [],
                borderWidth: 0,
            }
        ]
    })

    const donutStyle = {
        height: '100px',
        width: '100px'
    }

    // only add the section to the circle if the percentage is > 0
    // if there is overdelivered, by default the whole circle has green background and green section is not needed
    if (overDeliveredAmountRaw > 0) {
        donutData.value.datasets[0].data.push(donutOverDeliveredPercentage)
        donutData.value.datasets[0].backgroundColor.push('#F41C46')
    } else {
        if (donutDeliveredPercentage > 0) {
            donutData.value.datasets[0].data.push(donutDeliveredPercentage)
            donutData.value.datasets[0].backgroundColor.push('#77BF2F')
        }
    }
    if (donutIncomingPercentage > 0) {
        donutData.value.datasets[0].data.push(donutIncomingPercentage)
        donutData.value.datasets[0].backgroundColor.push('#2D70FA')
    }

    // fill the rest of the circle with the empty gray space, or in overdelivered case with the green space
    if (overDeliveredAmountRaw > 0) {
        donutData.value.datasets[0].data.push(100 - (donutOverDeliveredPercentage + donutIncomingPercentage))
        donutData.value.datasets[0].backgroundColor.push('#77BF2F')
    } else {
        donutData.value.datasets[0].data.push(100 - (donutDeliveredPercentage + donutIncomingPercentage))
        donutData.value.datasets[0].backgroundColor.push('#D2D9E0')
    }

    // Logic for clicking the card inside a slider, redirect to daily overview if clicked (except for the circle)
    function redirectToDailyView(event) {
        if ($(event.target).closest('.donut-container').length > 0) return false
        else if (props.isSliderItem) router.push({ name: 'daily-amount', params: { id: contractId.value }})
    }


    // Logic for clicking the circle
    function donutClick() {
        store.commit('amountItems/setAmountShowTonnage', !showTonnage.value)
    }


    // When the showTonnage changes by the function above, trigger the re-render
    watch(showTonnage, () => {
        // empty and refill the donut data in order to trigger re-rendering
        const oldData = donutData.value

        donutData.value = {
            datasets: [
            {
                data: [],
                backgroundColor: [],
                borderWidth: 0,
            }
        ]}

        // without timeout it doesn't trigger the animation
        setTimeout(() => {
            donutData.value = oldData
        })
    })
</script>

<style lang="sass" scoped>
.dark-mode
    .daily-amount-card
        background-color: $dark-button-gray
        border: 1px solid $component-border

        .daily-amount-header
            .daily-amount-material
                span
                    color: $dark-text-color

            .daily-amount-table-container
                .daily-amount-table
                    tr
                        td
                            color: $white

        .daily-amount-amounts
            .daily-amount-amounts-table-container
                .daily-amount-table-title
                    .title, .data
                        color: $dark-text-color

                .daily-amount-table
                    td
                        color: $white

            .donut-container
                .donut-text
                    color: $white

.daily-amount-card
    border-radius: 3px
    width: 94%
    min-width: 250px
    max-width: 408px
    min-height: 236px
    padding: 15px 10px 10px 10px
    background-color: $white
    margin: 5px 8px 8px 8px
    filter: drop-shadow(0px 3px 3px #01000029)
    box-sizing: border-box
    text-align: left

    .daily-amount-header
        display: flex
        flex-direction: column

        .daily-amount-material
            display: flex
            align-items: center
            margin-left: 3px

            .title
                margin-right: 15px

            .name
                display: block
                width: 230px
                overflow: hidden
                text-overflow: ellipsis
                white-space: nowrap

            span
                font-family: PoppinsSemiBold
                font-size: 15px
                letter-spacing: 0.07px
                line-height: 23px
                color: $text-lighter-gray

            img
                margin-right: 7px
                height: 15px

        .daily-amount-table-container
            .daily-amount-table
                margin-top: 5px

                tr
                    td
                        font-family: PoppinsMedium
                        font-size: 12px
                        letter-spacing: 0.06px
                        line-height: 18px
                        color: $text-black

                        max-width: 240px
                        overflow: hidden
                        text-overflow: ellipsis
                        white-space: nowrap

                tr > :first-child
                    padding-right: 5px

    .daily-amount-amounts
        display: flex
        align-items: center
        margin-bottom: 10px

        .daily-amount-amounts-table-container
            .daily-amount-table-title
                display: flex
                align-items: center
                margin: 15px 0px 20px 3px

                .title
                    font-family: PoppinsMedium
                    font-size: 12px
                    letter-spacing: 0.02px
                    line-height: 24px
                    color: $dark-blue

                .data
                    margin-left: 15px
                    font-family: PoppinsSemiBold
                    font-size: 15px
                    line-height: 23px
                    white-space: nowrap
                    color: $dark-blue

            .daily-amount-table
                tr
                    td
                        font-family: PoppinsMedium
                        font-size: 12px
                        letter-spacing: 0.02px
                        color: $text-black

                        &.icon-container
                            display: flex
                            align-items: center

                            img
                                margin-right: 5px

                        &:last-child
                            text-align: end

                tr > :first-child
                    padding-right: 30px

        .donut-container
            position: relative
            margin-left: auto
            margin-right: 15px

            .donut-text
                position: absolute
                width: 80%
                top: 32px
                left: 10px
                font-family: PoppinsLight
                font-size: 25px
                letter-spacing: -1.5px
                text-align: center
                color: $text-black

                .percentage-unit
                    font-size: 21px

                &.overdelivered
                    color: $bright-red

                    .tonnage-unit
                        font-size: 15px

                &.empty
                    color: $gray-blue

    &.clickable
        cursor: pointer
</style>
