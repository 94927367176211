<script setup>
    import { onMounted, onBeforeUnmount } from 'vue'
    import DeliveryCard from '@/components/ContractDetails/DeliveryCard.vue'

    import '@/utils/slick/slick.min.js'

    const props = defineProps({
        items: Array
    })

    onMounted(() => {
        $('.delivery-slider').slick({
            arrows: false,
            dots: true
        })
    })

    onBeforeUnmount(() => {
		// Remove slick instance, called before unmount while the html is still reachable
		$('.delivery-slider').slick('unslick')
	})
</script>

<template>
    <div class="delivery-slider">
        <DeliveryCard
            v-for="item in props.items"
            :key="`item-${item.id}`"

            :id="item.id"
            :status="item.status"
            :deliveryNoteNumber="item.number"
            :plateData="item.vehicle_license_plate"
            :supplierName="item.spedition_name"
            :factoryName="item.factory_name"
            :factoryNumber="item.factory_number"
            :confirmedTime="item.confirmed_time"
            :weighingTime="item.weighing_time"
            :materials="item.materials"
        />
    </div>
</template>