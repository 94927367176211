import { createApp } from 'vue'
import OneSignalVuePlugin, { useOneSignal } from '@onesignal/onesignal-vue3'
import OneSignalHelper from '@/utils/OneSignalHelper'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { createHead } from '@vueuse/head'

// jQuery and Axios
window.$ = require('jquery')
window.axios = require('axios')

// Access to PHP routes through Routing.generate()
import Routing from '/builds/development/onsite-app/pimcore_core/public/js/router'
let routes = require('/builds/development/onsite-app/pimcore_core/public/js/fos_js_routes.json')

const domain = process.env.VUE_APP_DOMAIN_COCKPIT
const bamDomain = process.env.VUE_APP_DOMAIN_BAM_COCKPIT
routes.host = `${window.location.origin.includes("bam") ? bamDomain : domain}`
routes.scheme = process.env.VUE_APP_DOMAIN_COCKPIT_SCHEME

window.logoPath = routes.scheme + '://' + routes.host + '/on-site/logo'
window.faviconPath = routes.scheme + '://' + routes.host + '/on-site/favicon'
window.background = routes.scheme + '://' + routes.host + '/on-site/background'
window.backgroundx2 = routes.scheme + '://' + routes.host + '/on-site/background2x'
window.documentTitle = routes.scheme + '://' + routes.host + '/on-site/document-title' // TODO: dynamic title from domain

Routing.setRoutingData(routes)
window.Routing = Routing

// Fetch options
const oneSignalOptions = OneSignalHelper.getInitOptions()

const app = createApp(App)

const head = createHead()

app.use(OneSignalVuePlugin, oneSignalOptions).use(head).use(store).use(router)
app.mount('#app')

// Custom directive
app.directive('click-outside', {
    beforeMount: function (el, binding) {
      	el.clickOutsideEvent = function (event) {
			// Check that click was outside the el and his children
			if (!(el === event.target || el.contains(event.target))) {

				//Call method provided in attribute value
				binding.value()
			}
		}
		document.body.addEventListener('click', el.clickOutsideEvent)
    },
    beforeUnmount: function (el) {
      	document.body.removeEventListener('click', el.clickOutsideEvent)
    },
})
