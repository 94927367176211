<template>
    <div class="delivery-single-page">
        <Header :pageTitle="pageTitle" />

		<div class="delivery-container">
			<div class="qr-scan-success-container" v-if="showScanMessage">
				<div class="success-title">
					<img src="@/assets/icons/checkmark.svg" alt="checkmark_icon"/>
					{{'on_site.qr.scan_success'.trans()}}
				</div>
				<p class="success-construction-site-name">{{route.params.construction_site_name}}</p>
			</div>

			<router-link :to="{'name': backRoute, params: {'id': contractId} }" class="back-button">
				<img src="@/assets/icons/arrow-white.svg">
				{{ 'on_site.notification.single.back-button'.trans() }}
			</router-link>

			<div class="delivery-loader-container" v-if="isRequesting">
				<Loader />
			</div>
			<div class="delivery-inner" v-else>
				<div class="delivery-wrapper">
					<div class="delivery-title">{{'on_site.delivery'.trans()}} &nbsp;{{delivery.number}}</div>
					<table class="delivery-time-table">
						<tr>
							<td>
								<img src="@/assets/icons/calendar-gray.svg">
								{{'on_site.delivery.date'.trans()}}
							</td>
							<td>{{delivery.weighing_date ? delivery.weighing_date : '—' }}</td>
						</tr>
						<tr>
							<td>
								<img src="@/assets/icons/clock-gray.svg">
								{{'on_site.delivery.time'.trans()}}
							</td>
							<td>
								{{delivery.weighing_time ? delivery.weighing_time + ' ' + delivery.weighing_time_unit : '—' }}
							</td>
						</tr>
						<tr v-if="delivery.status != '0'">
							<td>
								<img src="@/assets/icons/clock-gray.svg">
								{{'on_site.delivery.time_delivered'.trans()}}
							</td>
							<td>
								{{delivery.confirmed_time ? delivery.confirmed_time + ' ' + delivery.weighing_time_unit : '—' }}
							</td>
						</tr>
					</table>
					<div class="delivery-material-container"
						v-for="material in delivery.materials"
						:key="material.number"
					>
						<div class="delivery-material-header">
							{{material.name ? material.name : '—'}}
							<span class="material-quantity">
								<img alt="Mountain" src="../../assets/icons/amount-gray.svg"/>
								{{material.quantity ? material.quantity + ' ' + material.quantity_unit : '—'}}
							</span>
						</div>
						<table class="delivery-material-table">
							<tr>
								<td>{{'on_site.delivery.material.category'.trans()}}</td>
								<td>{{material.category ? material.category : '—'}}</td>
							</tr>
							<tr>
								<td>{{'on_site.delivery.material.number'.trans()}}</td>
								<td>{{material.number ? material.number : '—'}}</td>
							</tr>
						</table>
					</div>
					<table class="delivery-data-table">
						<tr>
							<td>{{'on_site.delivery.licence_plate'.trans()}}</td>
							<td>{{delivery.vehicle_license_plate ? delivery.vehicle_license_plate : '—'}}</td>
						</tr>
						<tr>
							<td>{{'on_site.delivery.vehicle_type'.trans()}}</td>
							<td>{{delivery.vehicle_type ? delivery.vehicle_type : '—'}}</td>
						</tr>
						<tr>
							<td>{{'on_site.delivery.rounds'.trans()}}</td>
							<td>{{delivery.number_of_rounds != 0 ? delivery.number_of_rounds : '—'}}</td>
						</tr>
						<tr>
							<td>{{'on_site.delivery.carrier'.trans()}}</td>
							<td>{{delivery.spedition_name ? delivery.spedition_name : '—'}}</td>
						</tr>
						<tr>
							<td>{{'on_site.delivery.incoterm'.trans()}}</td>
							<td>{{delivery.incoterm ? delivery.incoterm : '—'}}</td>
						</tr>
						<tr>
							<td>{{'on_site.delivery.factory'.trans()}}</td>
							<td>{{delivery.factory_number ? delivery.factory_number + ' - ' + delivery.factory_name : '—'}}</td>
						</tr>
					</table>
					<div class="delivery-button-container">
						<button class="confirm-delivery" v-if="delivery.status == '0'" @click="confirmDelivery">
							{{'on_site.delivery.confirm_delivery'.trans()}}
						</button>
						<template v-else>
							<router-link :to="{ name: 'daily-amount', params: { id: contractId }}" v-if="showDailyButton">
								<button class="see-daily">
									{{'on_site.delivery.see_daily'.trans()}}
								</button>
            				</router-link>
							<router-link :to="{ name: 'delivery-confirmed', params: { id: contractId }}">
								<button class="see-daily">
									{{'on_site.delivery.see_confirmed_delivery'.trans()}}
								</button>
            				</router-link>
							<router-link :to="{name: backRoute, params: {'id': contractId}}">
								<button class="go-back" @click="handleClick">
									{{'on_site.delivery.back'.trans()}}
								</button>
							</router-link>
						</template>
					</div>
				</div>
			</div>
		</div>

        <Footer/>
    </div>
</template>

<script setup>
    import Header from '@/components/Common/Header.vue'
	import Footer from '@/components/Common/Footer.vue'
	import Loader from "@/components/Common/Loader.vue"
	import { computed } from 'vue'
	import { useRoute } from 'vue-router'
	import { useStore } from 'vuex'
	import moment from 'moment'

	const store = useStore()
	const route = useRoute()

	const showScanMessage = route.params.show_scan_message
	const pageTitle = computed(() => showScanMessage ? 'on_site.page-title.qr-scanner'.trans() : store.state.contracts.selectedContract.contract_name)
	const delivery = computed(() => store.state.deliveryItems.deliverySingle)
	const contractId = computed(() => store.state.contracts.selectedContract.contract_id)
	const isRequesting = computed(() => store.state.deliveryItems.isDeliveryItemsRequesting)
	const showDailyButton = computed(() => delivery.value.confirmed_date == moment().format('DD.MM.YYYY'))
	const backRoute = computed(() => store.state.deliveryItems.deliverySingleComingFrom ? store.state.deliveryItems.deliverySingleComingFrom : 'contract-detail')

	function confirmDelivery() {
		store.dispatch('deliveryItems/confirmDelivery', delivery.value.id)
	}
</script>

<style lang="sass" scoped>
.delivery-single-page
	background: $text-blue
	min-height: calc(100vh - 50px)
	padding-top: 51px
	padding-bottom: 81px

	.delivery-container
		margin-left: 10px
		margin-right: 10px
		padding-top: 14px

		.qr-scan-success-container
			position: relative
			margin: -16px -10px 10px -10px
			padding: 10px 20px 14px 20px
			z-index: 9997
			background-color: $background-gray

			img
				width: 34px
				height: auto
				margin-right: 10px

			.success-title
				display: flex
				align-items: center
				font-family: MontserratBold
				font-size: 16px
				text-align: left
				color: $green

			.success-construction-site-name
				margin: 0px
				padding-left: 45px
				font-family: PoppinsBold
				font-size: 14px
				letter-spacing: 0.03px
				text-align: left
				color: $text-black

		.back-button
			display: flex
			margin-bottom: 10px
			padding-left: 14px
			font-size: 14px
			font-weight: normal
			font-family: 'PoppinsSemiBold'
			line-height: 21px
			text-align: left
			text-decoration: none
			color: $white

			img
				width: 6px
				height: auto
				margin-right: 7px
				transform: scaleX(-1)

		.delivery-inner
			max-width: 500px
			margin-left: auto
			margin-right: auto
			border-radius: 4px
			box-shadow: 0 3px 3px #00000029 !important
			background: $white

			.delivery-wrapper
				text-align: left
				margin-left: 8px
				margin-right: 8px
				padding-top: 12px
				padding-bottom: 11px

				.delivery-title
					padding-left: 2px
					padding-right: 2px
					margin-bottom: 5px
					font-family: PoppinsBold
					font-size: 14px
					letter-spacing: 0.07px

				table
					width: 100%

					tr
						td
							font-family: PoppinsMedium
							font-size: 12px
							letter-spacing: 0.02px
							color: $text-black

				.delivery-time-table
					border-bottom: 1px solid rgba(98, 112, 134, 0.5)
					padding-bottom: 6px

					tr
						td
							padding-bottom: 4px
							color: $text-lighter-gray

							img
								width: 17px
								height: auto
								margin-bottom: -5px
								margin-right: 4px

							&:first-child
								width: 32%

				.delivery-material-container
					border-bottom: 1px solid rgba(98, 112, 134, 0.5)
					margin-bottom: 12px

					.delivery-material-header
						display: flex
						justify-content: space-between
						align-items: flex-end
						margin-top: 8px
						margin-bottom: 5px
						padding-left: 2px
						padding-right: 2px
						font-family: PoppinsSemiBold
						font-size: 13px
						letter-spacing: 0.07px
						color: $text-black

						.material-quantity
							min-width: max-content
							color: $gray-blue

					.delivery-material-table
						padding-bottom: 13px

						tr
							td
								vertical-align: baseline

								&:first-child
									width: 37%

				.delivery-data-table
					width: 100%
					border-bottom: 1px solid rgba(98, 112, 134, 0.5)

					tr
						td
							vertical-align: baseline

							&:first-child
								width: 37%

				.delivery-button-container
					display: flex
					flex-direction: column
					margin-top: 16px
					padding-left: 2px
					padding-right: 2px

					button
						width: 100%
						padding-top: 13px
						padding-bottom: 13px
						margin-bottom: 5px
						border: none
						border-radius: 3px
						filter: drop-shadow(0px 1px 0px #01000029)
						font-family: PoppinsMedium
						font-size: 16px
						color: $white

						&.confirm-delivery
							background-color: $green

						&.see-daily,
						&.go-back
							background-color: $dark-blue

		.delivery-loader-container
			display: flex
			align-items: center
			justify-content: center
			max-width: 500px
			min-height: 200px
			margin-left: auto
			margin-right: auto
			padding-bottom: 15px
			padding-top: 2px
			border-radius: 4px
			box-shadow: 0 3px 3px #00000029
			background: $white		

.dark-mode
	.delivery-single-page
		.delivery-container
			.delivery-inner
				background-color: $text-black

				.delivery-wrapper
					.delivery-title
						color: $white

					.delivery-material-container
						.delivery-material-header
							color: $white

							.material-quantity
								color: $dark-text-color

					table
						tr
							td
								color: $white

					.delivery-time-table
						tr
							td
								color: $dark-text-color
					
					.delivery-button-container
						a
							button
								background-color: $button-blue

			.delivery-loader-container
				background-color: $dark-button-gray
				border: 1px solid $component-border
</style>

<style lang="sass">
.delivery-single-page
	.main-header
		border-bottom: 2px solid $background-gray
</style>