<script setup>
    import { computed, onMounted } from 'vue'
    import { useStore } from 'vuex'

    import CallOff from './types/CallOff.vue'
    import DeliveryNote from './types/DeliveryNote.vue'
    import Loader from '@/components/Common/Loader.vue'

    const store = useStore()

    const props = defineProps({
        listData: Object,
        isRequesting: Boolean
    })

    const items = computed(() => props.listData.items)
    const pagination = computed(() => props.listData.pagination)
    const isRequesting = computed(() => props.isRequesting)

    onMounted(() => {
        initializeInfiniteScroll()
    })

    const initializeInfiniteScroll = () => {
        window.onscroll = () => {
            const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.documentElement.offsetHeight
            if (bottomOfWindow && !isRequesting.value) {
                const { current_page, has_next_page } = pagination.value
                if (has_next_page) {
                    store.dispatch('messages/fetchNotificationsPaginated', {
                        page: current_page + 1,
                    })
                }
            }
        }
    }
</script>

<template>
    <div class="list-container">
        <router-link
            class="list-item-container"
            v-for="item in items"
            :key="`item-${item.id}`"
            :to="{ name: 'message-single', params: { id: item.id } }"
        >
            <DeliveryNote v-if="item.type === 'delivery_note.created'" :data="item" />
            <CallOff v-else :data="item" />
        </router-link>
        <Loader v-if="isRequesting" class="small" />
    </div>
</template>

<style lang="sass">
.list-container
    display: flex
    flex-direction: row
    flex-wrap: wrap

    .list-item-container
        display: flex
        width: 50%

        padding: 5px
        text-decoration: none

        @media (max-width: 720px)
            width: 100%

        .list-item
            width: 100%

    .loader-container
        width: 100%
        margin-top: 10px

    svg
        width: 80px !important
        height: 80px !important

</style>